<template>
  <div class="setting_main_bg">
    <div class="flexAC" v-if="companyid === ''">
      <div class="iconfont iconjia settingAdd" @click="handleAdd"><span>新增</span></div>
    </div>
    <div class="flexAC search_top">
      <el-input v-model="cont"  placeholder="请输入搜索内容" class="contInput"></el-input>
      <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
      <el-button class="settingReset" @click="handleReset">重置</el-button>
    </div>
    <div class="et_table">
      <el-table
        stripe
        :data="equipTypeList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="类型名称"
          prop="equtypename"
          width="150">
        </el-table-column>
        <el-table-column
          label="类型概述"
          prop="equtypemark"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="接入时间"
          width="150"
          prop="creatime">
        </el-table-column>
        <el-table-column
          v-if="companyid === ''"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small"  @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" style="color:red;"  @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>

    <!-- 新增弹框 -->
    <el-dialog title="设备类型新增" :visible.sync="addFromShow" width="600px" center class="gyDialog typeDia" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="formRules" ref="addFrom">
        <el-form-item label="类型名称" class="foitem" prop="equtypename">
          <el-input v-model="addfrom.equtypename"  placeholder="请输入类型名称"></el-input>
        </el-form-item>
        <el-form-item label="类型描述" class="foitem" prop="equtypemark">
          <el-input v-model="addfrom.equtypemark"  placeholder="请输入类型描述"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="AddFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!--  编辑  -->
    <el-dialog title="设备类型编辑" :visible.sync="editFromShow" width="600px" center class="gyDialog typeDia" :close-on-click-modal="false" :before-close="editFromCancel">
      <el-form :model="editfrom" :rules="editformRules" ref="editFrom">
        <el-form-item label="类型名称" class="foitem" prop="equtypename">
          <el-input v-model="editfrom.equtypename"  placeholder="请输入类型名称"></el-input>
        </el-form-item>
        <el-form-item label="类型描述" class="foitem" prop="equtypemark">
          <el-input v-model="editfrom.equtypemark"  placeholder="请输入类型描述"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="editFromCancel">取消</div>
        <div class="settingConfirm" @click="editFromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getEquipType, addEquipType, editEquipType, deleteEquipType } from '@/http/api/equip'
export default {
  name: 'type',
  data () {
    return {
      cont: '',
      equipTypeList: [],
      page: 1,
      size: 10,
      total: 0,
      addFromShow: false,
      addfrom: {
        equtypename: '',
        equtypemark: ''
      },
      formRules: {
        equtypename: [
          { required: true, trigger: 'blur', message: '请输入设备类型' }
        ],
        equtypemark: [
          { required: true, trigger: 'blur', message: '请输入类型描述信息' }
        ]
      },
      editFromShow: false,
      editfrom: {},
      editformRules: {
        equtypename: [
          { required: true, trigger: 'blur', message: '请输入设备类型' }
        ],
        equtypemark: [
          { required: true, trigger: 'blur', message: '请输入类型描述信息' }
        ]
      },
      companyid: ''
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.init()
  },
  methods: {
    init () {
      const parmas = {
        equtypename: this.cont,
        page_index: this.page,
        page_size: this.size
      }
      getEquipType(parmas).then(res => {
        if (res.status === '1') {
          this.equipTypeList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.equipTypeList = []
          this.total = 0
        }
      })
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.cont = ''
      this.init()
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    // 展示添加框
    handleAdd () {
      this.addFromShow = true
    },
    addFromCancel () {
      this.addFromShow = false
    },
    // 新增提交
    AddFromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          equtypename: this.addfrom.equtypename,
          equtypemark: this.addfrom.equtypemark
        }
        addEquipType(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 展示编辑框
    handleEdit (row) {
      this.editFromShow = true
      this.editfrom = { ...row }
    },
    // 关闭编辑框
    editFromCancel () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
    },
    // 编辑提交
    editFromConfirm () {
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        const params = {
          equtypename: this.editfrom.equtypename,
          equtypemark: this.editfrom.equtypemark,
          equtypeid: this.editfrom.equtypeid
        }
        editEquipType(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        deleteEquipType({ equtypeid: row.equtypeid }).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.search_top{
  margin-top: 20px;
}
.typeDia >>> .el-dialog{
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  max-height:calc(100% - 30px);
  max-width:calc(100% - 30px);
}
.typeDia >>> .el-dialog .el-dialog__body{
  flex:1;
  overflow: auto;
}
</style>
